
// import React from 'react';
// import './ContactUs.css'; // Import the CSS file for styling
// import fitnessImage from '../images/your-image.jpg'; // Adjust the image file name as per your image

// const ContactUs = () => {
//   return (
//     <div className="contact-container">
//       <div className="contact-content">
//         <h1 className="contact-heading">Fitness Sports Club</h1>
//         <p className="contact-subheading">
//           Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
//         </p>
//         <div className="contact-form">
//           <h2>Contact Us</h2>
//           <form>
//             <input type="text" placeholder="Enter your Name" />
//             <input type="email" placeholder="Enter a valid email address" />
//             <textarea placeholder="Enter your message"></textarea>
//             <button type="submit">Submit</button>
//           </form>
//         </div>
//       </div>
//       <div className="contact-image">
//         <img src={fitnessImage} alt="Fitness" />
//       </div>
//     </div>
//   );
// }

// export default ContactUs;



import React from 'react';
import './ContactUs.css'; // Import the CSS file for styling
import fitnessImage from '../images/your-image.png'; // Adjust the image file name as per your image
import Navbar from './Navbar'; // Import Navbar
import Footer from './Footer'; // Import Footer

const ContactUs = () => {
  return (
    <div>
          <Navbar/>
    <div className="contact-container">
         {/* Top section for heading and subheading */}
      <div className="contact-top">
        <h1 className="contact-heading">Fitness Sports Club</h1>
        <p className="contact-subheading">
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        </p>
      </div>

      {/* Bottom section for form and image */}
      <div className="contact-content">
        <div className="contact-form">
          <h2>Contact Us</h2>
          <form>
            <input type="text" placeholder="Enter your Name" />
            <input type="email" placeholder="Enter a valid email address" />
            <textarea placeholder="Enter your message"></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="contact-image">
          <img src={fitnessImage} alt="Fitness" />
        </div>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default ContactUs;
